@charset "UTF-8";
/* # =================================================================
# Global selectors
# ================================================================= */
html {
  box-sizing: border-box;
  overflow-y: scroll;
  /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

/* Remove margin, padding of all elements and set background-no-repeat as default */
* {
  background-repeat: no-repeat;
  /* Set `background-repeat: no-repeat` to all elements */
  padding: 0;
  /* Reset `padding` and `margin` of all elements */
  margin: 0;
}

/* # =================================================================
# General elements
# ================================================================= */
/* Add the correct display in iOS 4-7.*/
audio:not([controls]) {
  display: none;
  height: 0;
}

hr {
  overflow: visible;
  /* Show the overflow in Edge and IE */
}

/*
* Correct `block` display not defined for any HTML5 element in IE 8/9
* Correct `block` display not defined for `details` or `summary` in IE 10/11
* and Firefox
* Correct `block` display not defined for `main` in IE 11
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

summary {
  display: list-item;
  /* Add the correct display in all browsers */
}

small {
  font-size: 80%;
  /* Set font-size to 80% in `small` elements */
}

[hidden],
template {
  display: none;
  /* Add the correct display in IE */
}

abbr[title] {
  border-bottom: 1px dotted;
  /* Add a bordered underline effect in all browsers */
  text-decoration: none;
  /* Remove text decoration in Firefox 40+ */
}

a {
  background-color: transparent;
  /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects;
  /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
}

a:active,
a:hover {
  outline-width: 0;
  /* Remove the outline when hovering in all browsers */
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* Specify the font family of code elements */
}

b,
strong {
  font-weight: bolder;
  /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */
}

dfn {
  font-style: italic;
  /* Address styling not present in Safari and Chrome */
}

/* Address styling not present in IE 8/9 */
mark {
  background-color: #ff0;
  color: #000;
}

/* https://gist.github.com/unruthless/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* # =================================================================
# Forms
# ================================================================= */
/* Apply cursor pointer to button elements */
button,
[type="button"],
[type="reset"],
[type="submit"]
[role="button"] {
  cursor: pointer;
}

/* Replace pointer cursor in disabled elements */
[disabled] {
  cursor: default;
}

[type="number"] {
  width: auto;
  /* Firefox 36+ */
}

[type="search"] {
  -webkit-appearance: textfield;
  /* Safari 8+ */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* Safari 8 */
}

textarea {
  overflow: auto;
  /* Internet Explorer 11+ */
  resize: vertical;
  /* Specify textarea resizability */
}

optgroup {
  font-weight: bold;
  /* Restore the font weight unset by the previous rule. */
}

button {
  overflow: visible;
  /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS */
}

button,
select {
  text-transform: none;
  /* Firefox 40+, Internet Explorer 11- */
}

/* Remove the default button styling in all browsers */
/* Style select like a standard input */
select {
  /*  -moz-appearance: none;  Firefox 36+ */
  /*  -webkit-appearance: none;  Chrome 41+ */
}

select::-ms-expand {
  display: none;
  /* Internet Explorer 11+ */
}

select::-ms-value {
  color: currentColor;
  /* Internet Explorer 11+ */
}

legend {
  border: 0;
  /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit;
  /* Correct the color inheritance from `fieldset` elements in IE */
  display: table;
  /* Correct the text wrapping in Edge and IE */
  max-width: 100%;
  /* Correct the text wrapping in Edge and IE */
  white-space: normal;
  /* Correct the text wrapping in Edge and IE */
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit;
  /* Change font properties to `inherit` in Chrome and Safari */
}

[type="search"] {
  -webkit-appearance: textfield;
  /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px;
  /* Correct the outline style in Safari */
}

/* # =================================================================
# Specify media element style
# ================================================================= */
img {
  border-style: none;
  /* Remove border when inside `a` element in IE 8/9/10 */
  max-width: 100%;
  height: auto;
}

/* Add the correct vertical alignment in Chrome, Firefox, and Opera */
progress {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
  /* Internet Explorer 11- */
}

audio,
canvas,
progress,
video {
  display: inline-block;
  /* Internet Explorer 11+, Windows Phone 8.1+ */
}

/* # =================================================================
# Accessibility
# ================================================================= */
/* Hide content from screens but not screenreaders */
@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}

/* Specify the progress cursor of updating elements */
[aria-busy="true"] {
  cursor: progress;
}

/* Specify the pointer cursor of trigger elements */
[aria-controls] {
  cursor: pointer;
}

/* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
[aria-disabled] {
  cursor: default;
}

/* # =================================================================
# Selection
# ================================================================= */
/* Specify text selection background color and omit drop shadow */
::-moz-selection {
  background-color: #b3d4fc;
  /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.mb0 {
  margin-bottom: 0px;
}

.mt0 {
  margin-top: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mt5 {
  margin-top: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mt15 {
  margin-top: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mt25 {
  margin-top: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt30 {
  margin-top: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mt35 {
  margin-top: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt40 {
  margin-top: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mt45 {
  margin-top: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt50 {
  margin-top: 50px;
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz19 {
  font-size: 19px;
}

.fz20 {
  font-size: 20px;
}

.fz21 {
  font-size: 21px;
}

.fz22 {
  font-size: 22px;
}

.fz23 {
  font-size: 23px;
}

.fz24 {
  font-size: 24px;
}

.fz25 {
  font-size: 25px;
}

.fz26 {
  font-size: 26px;
}

.fz27 {
  font-size: 27px;
}

.fz28 {
  font-size: 28px;
}

.fz29 {
  font-size: 29px;
}

.fz30 {
  font-size: 30px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.cl:after {
  display: block;
  content: '';
  clear: both;
}

.pc_block {
  display: block;
}

@media only screen and (max-width: 767px) {
  .pc_block {
    display: none;
  }
}

.pc_inline {
  display: inline;
}

@media only screen and (max-width: 767px) {
  .pc_inline {
    display: none;
  }
}

.sp_block {
  display: none;
}

@media only screen and (max-width: 767px) {
  .sp_block {
    display: block;
  }
}

.sp_inline {
  display: none;
}

@media only screen and (max-width: 767px) {
  .sp_inline {
    display: inline;
  }
}

.inner {
  width: 1000px;
}

@media only screen and (max-width: 767px) {
  .inner {
    width: 100%;
  }
}

html {
  font-size: 62.5%;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
}

@media only screen and (max-width: 767px) {
  html {
    font-size: calc(100vw / 32);
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  font-size: 1.4rem;
  line-height: 2.25;
  color: #474747;
}

@media only screen and (max-width: 767px) {
  p {
    font-size: 1rem;
  }
}

.bold {
  font-weight: bold;
}

body {
  background: url(../images/memphis-colorful.png);
  font-family: "Rounded Mplus 1c";
  overflow: hidden;
}

.container {
  width: 100vw;
  height: 100vh;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainTitle {
  margin-bottom: 0;
  font-size: 6rem;
  color: #3c3c3c;
  font-family: "Rounded Mplus 1c";
}

.mainWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.nameList {
  width: 300px;
  margin-left: auto;
  height: 320px;
  resize: none;
  background: rgba(255, 255, 255, 0.8);
  border: 5px solid #3c3c3c;
  padding: 1em;
  margin-bottom: 1em;
  font-size: 1.5rem;
  line-height: 1.7;
  font-weight: bold;
}

.submit {
  background: #3c3c3c;
  color: #fff;
  width: 300px;
  line-height: 1;
  font-size: 2rem;
  padding: .7em 0;
  border: 5px solid #3c3c3c;
  transition: .3s;
  outline: none;
  font-family: "Rounded Mplus 1c";
}

.submit:hover {
  background: rgba(220, 220, 220, 0.6);
  color: #3c3c3c;
  font-weight: bold;
}

.mapWrap {
  font-family: "Rounded Mplus 1c";
  width: 90%;
  padding-left: 10px;
  margin: 0 auto 20px;
  line-height: 1;
  font-size: 2.8rem;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 3px solid #3c3c3c;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.mapWrap .mapHeader {
  margin-right: 15px;
}

.mapWrap #mapName {
  font-size: 2.2rem;
}

.resultWrap {
  width: 700px;
  padding: 3em;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.resultWrap .team {
  background: rgba(255, 255, 255, 0.8);
  width: 49%;
  height: 350px;
}

.resultWrap .team.orange {
  border: 5px solid #fe4301;
}

.resultWrap .team.blue {
  border: 5px solid #006fff;
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightSide {
  margin-top: 2rem;
}

.teamHeader {
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
  padding: .3em 0;
  margin-bottom: 1em;
}

.teamHeader.orange {
  background: #fe4301;
}

.teamHeader.blue {
  background: #006fff;
}

.member li {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1em;
}
