@charset "UTF-8";

$orange: rgba(254,67,1,1);
$blue: rgba(0,111,255,1);

body {
  background: url(../images/memphis-colorful.png);
  font-family: "Rounded Mplus 1c";
  overflow: hidden;
}

.container {
  // width: 1000px;
  width: 100vw;
  // max-width: 1000px;
  height: 100vh;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mainTitle {
  margin-bottom: 0;
  font-size: 6rem;
  color: #3c3c3c;
  font-family: "Rounded Mplus 1c";
}

.mainWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.nameList {
  width: 300px;
  margin-left: auto;
  height: 320px;
  resize: none;
  background: rgba(#fff,.8);
  border: 5px solid #3c3c3c;
  padding: 1em;
  margin-bottom: 1em;
  font-size: 1.5rem;
  line-height: 1.7;
  font-weight: bold;
}

.submit {
  background: #3c3c3c;
  color: #fff;
  width: 300px;
  line-height: 1;
  font-size: 2rem;
  padding: .7em 0;
  border: 5px solid #3c3c3c;
  transition: .3s;
  outline: none;
  font-family: "Rounded Mplus 1c";
  &:hover {
    background: rgba(#dcdcdc, .6);
    color: #3c3c3c;
    font-weight: bold;
  }
}

.mapWrap {
  font-family: "Rounded Mplus 1c";
  width: 90%;
  padding-left: 10px;
  margin: 0 auto 20px;
  line-height: 1;
  font-size: 2.8rem;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 3px solid #3c3c3c;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: rgba(#fff,.7);
  .mapHeader {
    margin-right: 15px;
  }
  #mapName {
    font-size: 2.2rem;
  }
}

.resultWrap {
  width: 700px;
  // margin: 5rem auto;
  padding: 3em;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .team {
    background: rgba(255,255,255,.8);
    width: 49%;
    height: 350px;
    &.orange {
      border: 5px solid $orange;
    }
    &.blue {
      border: 5px solid $blue;
    }
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightSide {
  margin-top: 2rem;
}

.teamHeader {
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
  padding: .3em 0;
  margin-bottom: 1em;
  &.orange {
    background: $orange;
  }
  &.blue {
    background: $blue;
  }
}

.member {
  li {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1em;
  }
}


