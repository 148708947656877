@charset "utf-8";

//////////////////////////////////////// 
// フォントパターン
////////////////////////////////////////
$yu: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
$sawarabi: "Sawarabi Mincho", serif;
$meiryo: Meiryo, "メイリオ", "ＭＳ ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3";
$hiragino: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
$min: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", YuMincho, "HG明朝E", "ＭＳ Ｐ明朝", serif;
$yu_min: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
$tsuku: "fot-tsukubrdgothic-std", sans-serif;
$mplus: "Mplus 1p", sans-serif;
$arima: 'Arima Madurai', cursive;
$forum: 'Forum', cursive;
$gv: 'Great Vibes', cursive;
$ten: "ten-mincho";
$hannari: "Hannari", serif;
$mar: 'Marcellus', serif;

@mixin bpm($point)
{
  @if $point == lg {
    @media only screen and (min-width: 992px)
    {
      @content;
    }
  }
  @if $point == md {
    @media only screen and (max-width: 991px)
    {
      @content;
    }
  }
  @else if $point == sm {
    @media only screen and (max-width:767px)
    {
      @content;
    }
  }
}

// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
// AIデータのフォントサイズをそのまま入れると割と良い感じになるようにしてます
@function get_vw($size, $viewport:320){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_sm($font_size:10){
  font-size: $font_size * .65px;
  font-size: get_vw($font_size);
}

@function get_vw_md($size, $viewport:640){
  $rate: 100 / $viewport;
  @return $rate * $size * .82vw;
}

@mixin fz_md($font_size:10){
  font-size: $font_size * .65px;
  font-size: get_vw_md($font_size);
}

// 正方形　
@mixin square($size: 100px) {
  width: $size;
  height: $size;
}


@for $i from 0 through 10 {
  .mb#{$i * 5} {
    margin-bottom:#{$i * 5}px;
  }
  .mt#{$i * 5} {
    margin-top:#{$i * 5}px;
  }
}
@for $i from 10 through 30 {
  .fz#{$i} {
    font-size: #{$i}px;
  }
}

.left { float: left; }
.right { float: right; }

.cl:after {
  display: block;
  content: '';
  clear: both;
}

.pc_block {
  display: block;
  @include bpm(sm) {
    display: none;
  }
}
.pc_inline {
  display: inline;
  @include bpm(sm) {
    display: none;
  }
}

.sp_block {
  display: none;
  @include bpm(sm) {
    display: block;
  }
}
.sp_inline {
  display: none;
  @include bpm(sm) {
    display: inline;
  }
}

.inner {
  width: 1000px; // default project width
  @include bpm(sm) {
    width: 100%;
  }
}

html {
  // overflow: scroll;
  font-size: 62.5%;
  font-family: $hiragino;
  @include bpm(sm) {
    font-size: calc(100vw / 32);
  }
}
body {
  
  @include bpm(sm) {
    // background: #000;
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  font-size: 1.4rem;
  line-height: 2.25;
  color: $blk;
  // @include bpm(md) {
  //   @include fz_md(17);
  //   line-height: 2.11;
  // }
  @include bpm(sm) {
    font-size: 1rem;
  }
}

.bold {
  font-weight: bold;
}