@charset "UTF-8";

//////////////////////////////////////// 
// カラーパターン
////////////////////////////////////////

$l_beige: #F4EDE6;
$brown_01: #B88E49;
$brown_02: #AA7724;
$blk: #474747;
$gly: #B2B2B2;